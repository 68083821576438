import { FC, useContext, useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { Context } from '.'
import { useLocation } from 'react-router-dom'
import useWindowDimensions from './core/hooks/useWindowDimensions'
import AppRouter from './components/AppRouter'

import { useGeolocated } from "react-geolocated"

import './styles/App.scss';
import Footer from './components/Footer'
import Header from './components/Header'
import { useFetching } from './core/hooks/useFetching'
import { ApiService } from './core/api/api'
import {isNullOrUndefined} from './core/utils/utils'
import OptionsModal from './pages/_shared/OptionsModal/OptionsModal'
import {StoreOrder} from './core/models/store/order'

declare const ymaps: any

const App: FC = () => {

  const { store, shopStore, studioStore, adminStore } = useContext(Context)

  const location = useLocation()
  
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })
  
  const { width, height } = useWindowDimensions()

  const [fetchSettings] = useFetching(async () => {
    const res = await ApiService.core.getSettings()

    let techworks = res.data.settings.find((v: any) => v.name == "techworks")

    if (techworks != undefined) {
      store.setTechworksState(techworks.data.state)
      store.setTechworksSetting(techworks)
    }

    store.setIsSettingsPreloaded(true)
    setTimeout(() => {
      store.setIsSettingsLoaded(true)
    }, 1000)
  })

  const [fetchAccount] = useFetching(async () => {
    const authed = store.checkAuth()

    if (authed) {
      try {
        const res = await ApiService.users.getAccount()
        const resOrders = await ApiService.studios.getMyOrders()
      
        let ordrs: any[] = resOrders.data.orders.sort((a: StoreOrder, b: StoreOrder) => {
          return a.status > b.status ? 1 : (a.status < b.status ? -1 : 0)
        })
        studioStore.setOrders(ordrs)

        console.log("account", res.data, resOrders.data)
        if (res.data.state == 1) {
          store.setupAuth(res.data.user.token, res.data.user)
        }
      } catch (err) {
        console.log("err", err)
      }
    }
  })

  const [fetchCities] = useFetching(async () => {
    const citiesRes = await ApiService.studios.getCities()

    let cities: any[] = citiesRes.data.cities.sort((a: any, b: any) => {
      return a.order > b.order ? 1 : (a.order < b.order ? -1 : 0)
    })

    studioStore.setCities(cities)

    if (isGeolocationEnabled && coords != undefined) {
      console.log("Geolocation enabled! Search user city...", coords)

      const geocoder = ymaps.geocode([coords.latitude, coords.longitude])

      geocoder.then((res: any) => {
        console.log(res)

        const cityChilds = res.geoObjects._collectionComponent._baseArrayComponent._children  
        
        console.log(cityChilds)

        if (!isNullOrUndefined(cityChilds) && cityChilds.length > 0) {
          const cityContain = cityChilds[0].properties._data.text
          let exists: any | null = null
          cities.forEach((city) => {
            console.log(cityContain.toLowerCase(), city.name.toLowerCase())
            if (cityContain.toLowerCase().includes(city.name.toLowerCase())) {
              exists = city
            }
          })

          console.log(exists)

          if (exists == null) {
            studioStore.setSelectedCity(cities[0])
            studioStore.setSelectedCityName(cities[0].name)
          } else {
            studioStore.setSelectedCity(exists)
            studioStore.setSelectedCityName(exists.name)

            // studioStore.openCityQuest()
          }
        }
      }, (err: any) => {
        console.log(err)
      })
    } else {
      studioStore.setSelectedCity(cities[0])
      studioStore.setSelectedCityName(cities[0].name)
    }
  })

  const [fetchStudios] = useFetching(async () => {
    const res = await ApiService.studios.getStudios({
      city_id: studioStore.selectedCity == null ? 0 : studioStore.selectedCity.id,
      page: studioStore.pageIndex,
      limit: studioStore.pageLimit,
      search: studioStore.search,
      category: studioStore.category,
      order: studioStore.order,
      order_by: studioStore.orderBy,
      status: studioStore.status,
      date: studioStore.searchDate,
      time: studioStore.searchTime,
      service: studioStore.searchService
    })

    studioStore.setTotalStudios(res.data.total)
    studioStore.setStudios(res.data.studios)
  })

  const [fetchCats] = useFetching(async () => {
    const res = await ApiService.studios.getCats()
    let cts: any[] = res.data.cats
    
    studioStore.setAllCats(cts)
  })

  const handleLeftPosition = () => {
    const cardwrap = document.getElementById("cardwrap")

    if (cardwrap != null) {
      console.log(cardwrap.offsetLeft)
      if (width > 960) {
        shopStore.setLeftPos(`${cardwrap.offsetLeft - 1}px`)
      } else if (width > 768) {
        shopStore.setLeftPos(`${(cardwrap.clientWidth / 2) - 190}px`)
      } else {
        shopStore.setLeftPos(``)
      }
    }
  }

  useEffect(() => {
    handleLeftPosition()
  }, [width])

  useEffect(() => {
    console.log(isGeolocationAvailable)
    console.log(isGeolocationEnabled)
    console.log(coords)
    if (coords != undefined) {
      fetchCities()
    }
  }, [coords])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true)
    } else {
      store.setIsMobile(false)
    }
  }, [width, height])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    handleLeftPosition()

    setTimeout(() => {
      handleLeftPosition()
      fetchCities()
    }, 1000)

    fetchCats()
    fetchSettings()
    fetchAccount()
    fetchStudios()
  }, [])

  return (
    <>
      <div className="xfader" style={{opacity: store.isSettingsLoaded ? "1" : "0"}}>

        {store.isSettingsPreloaded ? <>
        
          {store.techworksState && !location.pathname.includes("admin") && (!store.isAuth || (store.isAuth && store.userData.role != "ADMIN")) ? <>
            <div className={"placeholder"}>
              <div className={"ph_wrap"}>
                {/* TODO: Технические работы на сайте */}
              </div>
            </div>
          </> : (location.pathname.includes("/admin") ? <>
            <AppRouter />
          </> : <>
            <Header />
            <AppRouter />
            <Footer />

            <OptionsModal
              height="auto"
              openned={studioStore.isCityVisible}
              title="Выберите город"
              onClose={() => studioStore.setIsCityVisible(false)}
              done="Выбрать"
              onDone={() => {
                studioStore.setIsCityVisible(false)
              }}>
              <div className="cities_selecting">
                {studioStore.cities.map(city => <div className={"cs_item" + (studioStore.selectedCityName == city.name ? " selected" : "")} onClick={() => {
                    studioStore.setSelectedCity(city)
                    studioStore.setSelectedCityName(city.name)
                  }}>
                  <div className="cs_item__name">{city.name}</div>
                  <div className="cs_item__check"><span><i className="fas fa-check"></i></span></div>
                </div>)}
              </div>
            </OptionsModal>

          </>)}
        
        </> : <></>}

      </div>
    </>
  )
}

export default observer(App)
